import React from "react"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"

import Pick from "../front-page/HowItWorks/Pick"
import Peel from "../front-page/HowItWorks/Peel"
import Place from "../front-page/HowItWorks/Place"
import Video from "./Video"

const HowPageWrapper = styled(PageWrapper)`
  padding-top: 5rem;
  text-align: center;
`
const FlexRow = styled(Row)`
  position: relative;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const TitleWrapper = styled(Row)`
  margin: 0 0 1rem;
  text-align: center;

  h1 {
    margin: 0;
  }

  p {
    font-style: italic;
    color: ${props => props.theme.color.primary};
  }
`

const HowItWorksPage = () => (
  <HowPageWrapper>
    <Seo title="How It Works" />
    <Row>
      <FlexRow>
        <TitleWrapper>
          <h1>How to Blend a f'real</h1>
          <p>
            With just three easy steps, you'll be sipping a f'real in no time!
          </p>
        </TitleWrapper>
      </FlexRow>
      <FlexRow>
        <Pick />
        <Peel />
        <Place />
      </FlexRow>

      <FlexRow>
        <Video />
      </FlexRow>
    </Row>
  </HowPageWrapper>
)

export default HowItWorksPage
