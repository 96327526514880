import React from "react"
import styled from "styled-components"

import Row from "../../../styles/Row"

const Wrapper = styled.section`
  transition: background 0.2s ease-in-out;
`

const FlexRow = styled(Row)`
  position: relative;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2rem;
`

const IframeWrapper = styled.div`
  margin: 2rem 0;
  border-radius: 1rem;
  overflow: hidden;
`
const Iframe = styled.iframe`
  display: block;
  margin: 0;
  max-width: 100%;
  border: none;
`

export default function Video(props) {
  return (
    <Wrapper>
      <FlexRow>
        <IframeWrapper>
          <Iframe
            width="560"
            height="315"
            frameborder="0"
            src="https://www.youtube.com/embed/e5XSMRl2iB4"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
          />
        </IframeWrapper>
      </FlexRow>
    </Wrapper>
  )
}
